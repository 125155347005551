import React from "react";
import { PageLayout } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { Hero } from "@components/Hero/Hero";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import Margins from "@components/Margins/Margins";

export default function InformationTechnology() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "security",
			}}
		>
			<Margins>
				<HeroSection />
				<Commitment />
			</Margins>
			<RobustInfrastructure />
			<Margins className={styles.bottomContainer}>
				<ComplianceStandard />
				<JoinInstitutionsV2
					outerContainerClassName={styles.outerContainerClassName}
				/>
			</Margins>
		</PageLayout>
	);
}

// New component redesign starts here
const HeroSection = () => {
	const hero = {
		title: "Top-grade security for advanced research collaboration",
		description:
			"Protect your innovative work with Genemod’s cutting-edge data security and compliance solutions",
		image: (
			<StaticImage
				// src="../_assets/integration-hero.png"
				src="../../comparisons/_versusHelper/images/integration-hero.png"
				alt="A Central Protocol Hub for Smooth Collaboration"
			/>
		),
	};
	return (
		<Hero
			className={styles.hero}
			{...hero}
			heroTextContainerSize="small"
			tabletTextAlignment="center"
		/>
	);
};
const ComplianceStandard = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Compliance with global standards",
			image: (
				<StaticImage
					src={"../../../assets/products/platform-dashboard.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Genemod is purpose-built for compliance with 21 CFR Part 11, making it ideal for operations in regulated (GxP) environments",
				"Our commitment to FDA 21 CFR Part 11 regulations reflects our support for research in strictly regulated settings",
				"We prioritize user peace of mind in addition to functionality. We do this by aligning with GDPR and CCPA regulations, demonstrating our dedication to privacy rights and essential privacy obligations",
			],
		},
	];

	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return (
					<FeatureCard
						className={styles.container}
						{...card}
						key={index}
						orientation="right"
					/>
				);
			})}
		</FeatureCardLayout>
	);
};
const RobustInfrastructure = () => {
	const FeatureROI = () => (
		<StaticImage
			loading="eager"
			src="../../../assets/products/aws-cloud.png"
			alt="feature summary"
		/>
	);
	const featureSummaryContent = {
		title: "Robust infrastructure with AWS hosting",
		list: [
			"We go beyond identity and device management, specializing in end-to-end infrastructure security and comprehensive information governance",
			"Our partnership with Amazon Web Services (AWS) means that we have access to top-tier security and various certifications, including ISO 27001, SOC 1, and SOC 2",
			"Together with AWS, we’ve created a resilient infrastructure tailored to safeguard sensitive research data",
		],
		imageRight: true,
		img: <FeatureROI />,
	};
	return <FeatureSummary {...featureSummaryContent} />;
};
const Commitment = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "100% commitment to data protection",
			image: (
				<StaticImage
					src={"../../../assets/products/single-source-of-truth.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Data security is our top priority. Hundreds of leading research institutions trust us with their groundbreaking projects",
				"We don’t just focus on collaboration; we consider security a fundamental pillar so that you can preserve the integrity of your research",
				"We take pride in our enterprise-level security measures, supporting research organizations worldwide in achieving their goals",
			],
		},
	];

	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return (
					<FeatureCard
						className={styles.container}
						{...card}
						key={index}
						orientation="right"
					/>
				);
			})}
		</FeatureCardLayout>
	);
};
